<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex">
						<a-col span="12">
							<a-button-group>
								<a-button v-if="isShow" v-has="'mail:send'" @click="sendmail" type="primary">发送邮件</a-button>
								<a-button v-if="!isShow && isSend" v-has="'mail:send'" @click="reply" type="primary">回复邮件</a-button>
								<a-button v-if="!isShow && isSend" v-has="'mail:send'" @click="forward" type="primary">转发</a-button>
								<a-button v-if="!isShow && isSend" @click="black">返回</a-button>
							</a-button-group>
						</a-col>
						<a-col span="12">
							<a-row :gutter="15">
								<a-col span="20">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList()" @change="change"
													 placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="4">
									<a-select style="width:100%" v-model="param.isRead" @select="getList()">
										<a-select-option value="">所有</a-select-option>
										<a-select-option value="true">已读</a-select-option>
										<a-select-option value="false">未读</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<div v-if="isSend">
			<div v-if="isShow">
				<a-table :columns="columns" :scroll="{ x: '100%' }" row-key="id" :indentSize="25" :defaultExpandAllRows="true"
								 :style="{ background: '#FFF' }" :pagination="false" size="small" :data-source="data.records">
					<!-- 主题 -->
					<template slot-scope="row" slot="tit">
						<a @click="enter(row)">{{ row.title }}</a>
					</template>
					<!-- 阅读状态 -->
					<template slot-scope="row" slot="isRead">
						<my-icon type="icon-24gf-envelopeOpen2" v-if="row.isRead"></my-icon>
						<a-icon v-else type="mail" theme="twoTone" />
					</template>
					<!-- 操作 -->
					<template slot-scope="row" slot="action">
						<a-tooltip title="删除">
							<a-button type="link" v-has="'mail:receive:del'" icon="delete" @click="del(row.id)"></a-button>
						</a-tooltip>
					</template>
				</a-table>
				<!-- 分页 -->
				<a-row>
					<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
						<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
													:current="data.current" />
					</a-col>
				</a-row>
			</div>
			<!-- 查看信件 -->
			<mail :mail="mail" :type="'收件箱'" v-else></mail>
		</div>
		<postmail v-else @success="onsubmit" :isempty="true" :rowMail="rowMail"></postmail>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import { Icon } from 'ant-design-vue';
import postmail from './components/postmail.vue';
import attachView from '@/components/common/attach-view.vue';
import mail from './components/mail.vue';
const MyIcon = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/c/font_3394332_2wzi7hji7im.js' // 在 iconfont.cn 上生成
});
export default {
	components: { 'my-icon': MyIcon, postmail, attachView, mail },
	data() {
		return {
			isShow: true,
			mailId: '',
			mail: {},
			isSend: true,
			rowMail: {
				receiveNames: [],
				receiveIds: []
			},
			columns: [
				{ title: '状态', scopedSlots: { customRender: 'isRead' }, align: 'center', width: 100 },
				{ title: '主题', scopedSlots: { customRender: 'tit' }, width: 200 },
				{ title: '发件人', dataIndex: 'sendName', align: 'center', width: 200, ellipsis: true },
				{ title: '时间', dataIndex: 'createTime', align: 'center', width: 160 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			],
			data: {},
			param: {
				page: 1,
				limit: 10,
				keyword: '',
				isRead: ''
			}
		};
	},
	mounted() {
		this.getList();
	},

	methods: {
		...apiUtil,
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/mail/receive/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 回复邮件
		reply() {
			this.rowMail.title = '';
			this.rowMail.content = '';
			this.rowMail.attachment = [];
			this.isSend = false;
		},
		// 转发
		forward() {
			this.rowMail.title = this.mail.title;
			this.rowMail.content = this.mail.content;
			this.rowMail.attachment = this.mail.attachment;
			this.rowMail.receiveNames = [];
			this.rowMail.receiveIds = [];
			this.isSend = false;
		},
		change(e) {
			if (e.target.value == '') {
				this.getList();
			}
		},
		sendmail() {
			this.rowMail = {};
			this.isSend = false;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 点击返回
		black() {
			this.rowMail.receiveIds = [];
			this.rowMail.receiveNames = [];
			this.isShow = true;
			this.getList();
		},
		// 查看邮件
		enter(row) {
			utils.showSpin();
			this.http
				.get('/platform/mail/receive/read/' + row.id)
				.then(ret => {
					if (ret.code == 200) {
						utils.closeSpin();
						this.mail = ret.data;
						this.isShow = false;
						this.rowMail.receiveIds.push(ret.data.sendId);
						this.rowMail.receiveNames.push(ret.data.sendName);
					}
				})
				.catch(err => {
					console.log(err);
					utils.closeSpin();
				});
		},

		// 成功
		onsubmit() {
			this.rowMail.receiveIds = [];
			this.rowMail.receiveNames = [];
			this.isSend = true;
			this.isShow = true;
			this.getList();
		},
		// 删除邮件
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/mail/receive/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.isShow = true;
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		}
	}
};
</script>

<style scoped lang="less">
.title {
	font-size: 30px;
}

.mailtitle {
	font-size: 28px;
}

.container {
	display: flex;
	text-align: center;
}
</style>
